import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../../../redux/props'

export type Remove = (pageUUID: string) => Promise<boolean>

const useRemovePage = (): Remove => {
  const pageFeature = crud.use(props.PAGE)

  const remove = async (pageUUID: string) => {
    const status = await pageFeature.remove({ uuid: pageUUID })
    return !!status
  }

  return remove
}

export default useRemovePage
