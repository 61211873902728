import { useState } from 'react'
import Progress from '../Progress/Progress'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import DragAndDrop from '../DragAndDrop/DragAndDrop'

type PresignedUploadResponse = {
  presignedURL: string
  key: string
  url?: string
}

export type OnCallbackFile = {
  url: string
  key: string
  name: string
  mimeType: string
}

type Props = {
  label?: string
  hint?: string
  allowedMimeTypes: string[]
  maxFileSizeInBytes: number
  uploadType: UPLOAD_TYPES
  onFilesCallback: (files: OnCallbackFile[] | OnCallbackFile) => void
  children?: React.ReactNode
  multiple?: boolean
  className?: string
}

export enum UPLOAD_TYPES {
  CUSTOMER_IMAGE = 'CUSTOMER_IMAGE',
  PAGE_ASSET = 'PAGE_ASSET',
}

const DirectFileUpload: React.FC<Props> = ({ label, hint, allowedMimeTypes, maxFileSizeInBytes, onFilesCallback, uploadType, multiple = false }) => {
  const [progress, setProgress] = useState<number>(0)
  const getImageUploadPresignedURL = functional.use(Routes.GET_FILE_UPLOAD_PRESIGNED_URL)

  const process = async (files: File[]) => {
    const outputFiles: OnCallbackFile[] = []
    for (const file of files) {
      const response: PresignedUploadResponse = await getImageUploadPresignedURL({
        type: uploadType,
        meta: { type: file.type, name: file.name, size: file.size },
      })
      if (!response) continue
      const { presignedURL, url, key } = response

      const uploadResult = await s3Upload(presignedURL, file, setProgress)
      if (!uploadResult) continue
      outputFiles.push({ url, key, name: file.name, mimeType: file.type })
    }
    if (outputFiles.length > 0) {
      if (multiple) {
        onFilesCallback(outputFiles)
      } else {
        onFilesCallback(outputFiles[0])
      }
    }
  }

  return (
    <>
      <DragAndDrop
        onFiles={process}
        allowedMimeTypes={allowedMimeTypes}
        maxFileSizeInBytes={maxFileSizeInBytes}
        disabled={progress > 0 && progress < 100}
      >
        <div className="flex flex-col items-center justify-center py-10 bg-lightGray border border-black rounded-md">
          <div className="font-bold text-gray">{label}</div>
          <div className="text-sm text-gray">{hint}</div>
        </div>
      </DragAndDrop>
      <Progress progress={progress} />
    </>
  )
}

export default DirectFileUpload
