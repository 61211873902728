import { useParams } from 'react-router-dom'
import useGetCustomer from '../../../hooks/useGetCustomer'
import Image from '../../../UI/Image/Image'
import useSheetManager from '../SheetView/Page/useSheetManager/useSheetManager'
import FullScreenCalendar from '../../../UI/Calendar/FullScreenCalendar'

const SheetView = () => {
  const { uuid } = useParams()
  const sheetManager = useSheetManager(uuid)
  const sheet = sheetManager.getSheet()
  const customer = useGetCustomer(sheet?.customerUUID)

  if (!sheet || !customer) return null
  return (
    <div className="p-5">
      <div className="flex items-center gap-5">
        {!!customer.imageURL && (
          <div className="flex justify-center">
            <Image src={customer?.imageURL} className="w-[50px] h-[50px]" />
          </div>
        )}
        <div className="text-center text-xl">
          {customer.name}
          {` - `}
          <span className="text-xl font-bold">{sheet.name}</span>
        </div>
      </div>
      {Array.isArray(sheet.pages) && (
        <div className="mt-5">
          <FullScreenCalendar pages={sheet.pages} />
        </div>
      )}
    </div>
  )
}

export default SheetView
