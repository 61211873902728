import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  // admin features
  CUSTOMER: 'CUSTOMER',
  SHEET: 'SHEET',
  PAGE: 'PAGE',

  // public features
  LOCAL_SHEET: 'LOCAL_SHEET',
}

export default props
