import { useParams } from 'react-router-dom'
import useGetCustomer from '../../../hooks/useGetCustomer'
import Image from '../../../UI/Image/Image'
import PageOverview from './PageOverview/PageOverview'
import NewPage from './NewPage/NewPage'
import Page from './Page/Page'
import Container from '../../../UI/Container/Container'
import useSheetManager from './Page/useSheetManager/useSheetManager'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Calendar from '../../../UI/Calendar/Calendar'

const SheetView = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const sheetManager = useSheetManager(uuid)
  const sheet = sheetManager.getSheet()
  const customer = useGetCustomer(sheet?.customerUUID)

  if (!sheet || !customer) return null
  return (
    <Container className="mb-[30vh]">
      <PageOverview pages={sheet.pages} />
      <div className="flex items-center mt-5 gap-5">
        {!!customer.imageURL && (
          <div className="flex justify-center">
            <Image src={customer?.imageURL} className="w-[50px] h-[50px]" />
          </div>
        )}
        <div className="text-center text-xl">
          {customer.name}
          {` - `}
          <span className="text-xl font-bold">{sheet.name}</span>
        </div>
      </div>
      {Array.isArray(sheet.pages) && (
        <div>
          <div className="mt-5">
            <Calendar pages={sheet.pages} />
          </div>
          <div className="flex justify-center mt-5">
            <Button text={t.sheet.page.calendarFullscreen} newTab href={`/public/sheet-cal/${uuid}`} />
          </div>
        </div>
      )}
      <NewPage sheet={sheet} />
      {sheet.pages.map((p, i) => (
        <Page key={p.uuid} pageUUID={p.uuid} sheetManager={sheetManager} />
      ))}
      {Array.isArray(sheet.pages) && (
        <div className="fixed bottom-0 left-0 w-full p-2 bg-black z-20 flex justify-end">
          <Button text={t.sheet.page.save} onClick={sheetManager.save} />
        </div>
      )}
    </Container>
  )
}

export default SheetView
