import { useSelector } from 'react-redux'
import globalProps from '../../../redux/props'
import React, { useState } from 'react'
import Headline from '../Headline/Headline'
import Refresh from './Refresh'
import Loading from '../Loading/Loading'
import Search from './Search'

type Props = {
  title?: string
  data: any[]
  pull?: () => void
  filter: (search: string) => (item: any) => boolean
  Upsert?: React.FC<{ initData?: any; pullList: () => void }>
  Component: React.FC<{ data: any; pullList: () => void }>
  Header?: React.FC
}

const SimpleList: React.FC<Props> = ({ title, data, pull, filter, Upsert, Component, Header }) => {
  const t = useSelector((s) => s[globalProps.TRANSLATION])
  const [search, setSearch] = useState<string>('')

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-3 justify-between items-center">
        {!!title ? <Headline text={title} /> : <div />}
        <div className="flex flex-row gap-3 items-center">
          {!!Upsert && <Upsert pullList={pull} />}
          <Search setSearch={setSearch} search={search} />
          {!!pull && <Refresh pull={pull} />}
        </div>
      </div>
      <Loading loading={data} />
      {Array.isArray(data) && (
        <>
          <div className="mt-3">
            {data.filter(filter(search)).length}
            {data.filter(filter(search)).length === 1 ? t.UI.simpleList.resultAmountSuffixSingular : t.UI.simpleList.resultAmountSuffixPlural}
          </div>
          {Header && <Header />}
          {data.length > 0 && (
            <div>
              {data.filter(filter(search)).map((item) => {
                return (
                  <div className="flex flex-col lg:flex-row items-center border-t border-graySecondary first:border-none py-1" key={item.uuid}>
                    <Component data={item} pullList={pull} />
                  </div>
                )
              })}
            </div>
          )}
          {data.length === 0 && <div className="text-gray text-center text-sm mt-3">{t.UI.simpleList.noData}</div>}
        </>
      )}
    </div>
  )
}

export default SimpleList
