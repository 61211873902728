import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Customer from './Customer/Customer'
import GlobalSearch from './GlobalSearch/GlobalSearch'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'search'} element={<GlobalSearch />} />
        <Route path={'customer'} element={<Customer />} />
        <Route path={'*'} element={<Navigate replace to="/admin/customer" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
