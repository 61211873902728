import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import deLocale from '@fullcalendar/core/locales/de'
import './style.css'
import { Page } from '../../../types/Page'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getAverageDate } from '../../../utility'

type Props = {
  pages: Page[]
}

const Calendar: React.FC<Props> = ({ pages }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getEvents = () => {
    if (Array.isArray(pages)) {
      return pages.map((p, i) => {
        return {
          id: p.uuid,
          start: p.date,
          allDay: true,
          // backgroundColor: getGrayColor(255 - i * 25),
          backgroundColor: '#212529',
        }
      })
    }
  }

  const renderEvent = (eventInfo: any) => {
    const page = pages.find((p) => p.uuid === eventInfo.event.id)

    if (!page) return null
    return (
      <div className={'flex flex-row items-center items cursor-pointer w-full basis-full py-0 px-0 gap-2 shadow-calendar-entry'}>
        <div className="flex flex-row gap-2">
          <div className={'text-s text-wrap p-2 text-white'}>{`${page.topic || t.sheet.pageOverview.noTopic}`}</div>
        </div>
      </div>
    )
  }

  const goToPage = (info: any) => {
    const element = document.getElementById(`page-${info.event.id}`)
    element.scrollIntoView()
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      height={'auto'}
      events={getEvents()}
      eventContent={renderEvent}
      eventClick={goToPage}
      eventDisplay="block"
      locale={deLocale}
      fixedWeekCount={false}
      initialDate={getAverageDate(pages)}
      titleFormat={{ year: 'numeric', month: 'long' }}
      headerToolbar={{
        left: 'dayGridWeek,dayGridMonth',
        center: 'title',
        right: 'today,prev,next',
      }}
    />
  )
}

export default Calendar
