import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Sheet from './Sheet/Sheet'
import SheetView from './Sheet/SheetView/SheetView'
import SheetCalendarView from './Sheet/SheetCalendarView/SheetCalendarView'

const Public = () => {
  return (
    <Layout authLevelRequired={false}>
      <Routes>
        <Route path={'customer/:uuid'} element={<Sheet />} />
        <Route path={'sheet/:uuid'} element={<SheetView />} />
        <Route path={'sheet-cal/:uuid'} element={<SheetCalendarView />} />
      </Routes>
    </Layout>
  )
}

export default Public
