import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DocumentDuplicateIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/solid'
import Form from '../Form/Form'
import RemoveConfirmation from '../RemoveConfirmation/RemoveConfirmation'
import CopyConfirmation from '../CopyConfirmation/CopyConfirmation'

type Props = {
  title: string
  children: React.ReactNode
  formCallback?: () => Promise<boolean> | boolean
  Trigger: React.ReactNode
  triggerOnHover?: boolean
  removeCallback?: () => void
  removeItemName?: string
  copyCallback?: (name: string) => void
  copyItemName?: string
}

const SlideIn: React.FC<Props> = ({
  title,
  children,
  formCallback,
  Trigger,
  triggerOnHover = false,
  removeCallback,
  removeItemName,
  copyCallback,
  copyItemName,
}) => {
  const [open, setOpen] = useState(false)

  const submit = async () => {
    const result = await formCallback()
    setOpen(!result)
  }

  const internalCopyCallback = (name: string) => {
    copyCallback(name)
    setOpen(false)
  }

  const internalRemoveCallback = () => {
    removeCallback()
    setOpen(false)
  }

  return (
    <>
      {!triggerOnHover && <div onClick={() => setOpen(true)}>{Trigger}</div>}
      {triggerOnHover && <div onMouseEnter={() => setOpen(true)}>{Trigger}</div>}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-[26px] sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <XMarkIcon className="h-6 w-6 stroke-white outline-none" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-auto bg-black py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 flex flex-row items-center justify-between">
                          <div className="text-white flex items-center gap-1">
                            <PlusIcon className="h-7 stroke-white" />
                            {title}
                          </div>
                          <div className="flex items-center gap-2">
                            {copyCallback && copyItemName && (
                              <CopyConfirmation itemName={removeItemName} callback={internalCopyCallback}>
                                <DocumentDuplicateIcon className="fill-white w-5 cursor-pointer" />
                              </CopyConfirmation>
                            )}
                            {removeCallback && removeItemName && (
                              <RemoveConfirmation itemName={removeItemName} confirmationCallback={internalRemoveCallback}>
                                <TrashIcon className="fill-white hover:fill-red w-5 cursor-pointer" />
                              </RemoveConfirmation>
                            )}
                          </div>
                        </Dialog.Title>
                      </div>
                      {formCallback && (
                        <Form onSubmit={submit} className="relative mt-6 flex-1 px-4 sm:px-6">
                          {children}
                        </Form>
                      )}
                      {!formCallback && <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div>}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default SlideIn
