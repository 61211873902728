import { LinkIcon } from '@heroicons/react/24/outline'
import { Customer } from '../../../../types/Customer'
import Image from '../../../UI/Image/Image'
import Upsert from '../Upsert/Upsert'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { frontendDomain } from '../../../../utility'
import Copy from '../../../UI/Copy/Copy'

type Props = { data: Customer; pullList: () => void }

const Item: React.FC<Props> = ({ data, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <Image addPrefix src={data.imageKey} className="h-[30px] aspect-square" />
      <a className="w-10/12 block hover:underline" href={`/public/customer/${data.uuid}`}>
        {data.name}
      </a>
      <div className="w-2/12 flex justify-end items-center gap-5">
        <Copy tooltip={t.customer.list.copy} value={`${frontendDomain}/public/customer/${data.uuid}`}>
          <LinkIcon className="w-5 stroke-black" />
        </Copy>
        <Upsert initData={data} pullList={pullList} />
      </div>
    </div>
  )
}

export default Item
