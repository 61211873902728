import styled from 'styled-components'

export const StyledIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    bottom: 75px;
    right: 20px;
  }
`
