import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import Modal from '../Modal/Modal'

type Props = {
  itemName: string
  confirmationCallback: () => void
  children: React.ReactNode
}

const RemoveConfirmation: React.FC<Props> = ({ itemName, confirmationCallback, children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        noForm
        onConfirm={confirmationCallback}
        show={open}
        invert
        cancelButtonText={t.generic.cancel}
        onClose={toggle}
        title={t.UI.removeConfirmation.title}
      >
        <div className="flex flex-col text-white gap-3 mt-3">{t.UI.removeConfirmation.text(itemName)}</div>
      </Modal>
    </>
  )
}

export default RemoveConfirmation
