import { LinkIcon } from '@heroicons/react/24/outline'
import Upsert from './Upsert'
import { Sheet } from '../../../types/Sheet'
import Copy from '../../UI/Copy/Copy'
import { frontendDomain } from '../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type Props = { data: Sheet; pullList: () => void }

const Item: React.FC<Props> = ({ data, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <a className="w-10/12 block hover:underline" href={`/public/sheet/${data.uuid}`}>
        {data.name}
      </a>
      <div className="w-2/12 flex justify-end items-center gap-5">
        <Copy tooltip={t.sheet.list.copy} value={`${frontendDomain}/public/sheet/${data.uuid}`}>
          <LinkIcon className="w-5 stroke-black" />
        </Copy>
        <Upsert initData={data} pullList={pullList} />
      </div>
    </div>
  )
}

export default Item
