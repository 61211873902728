import { useState } from 'react'
import Input from '../../UI/Input/Input'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../hooks/useToast'
import Button, { Type } from '../../UI/Button/Button'
import SlideIn from '../../UI/SlideIn/SlideIn'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useNavigate, useParams } from 'react-router-dom'
import Tooltip from '../../UI/Tooltip/Tooltip'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Sheet } from '../../../types/Sheet'

type Props = {
  initData?: Sheet
  pullList?: () => void
}

const Upsert: React.FC<Props> = ({ initData, pullList }) => {
  const { uuid } = useParams()
  const [data, setData] = useState<Sheet>(initData || ({ customerUUID: uuid } as Sheet))
  const t = useSelector((s) => s[props.TRANSLATION])
  const sheetFeature = crud.use(props.SHEET)
  const toast = useToast()
  const navigate = useNavigate()

  const set = (key: keyof Sheet) => (value: any) => setData({ ...data, [key]: value })

  const save = async (): Promise<boolean> => {
    const sheet = await sheetFeature.upsert(data)
    if (sheet) {
      if (!!initData) {
        pullList()
        toast(t.sheet.upsert.success, ToastType.SUCCESS)
      } else {
        navigate(`/public/sheet/${sheet.uuid}`)
      }
      return true
    } else {
      toast(t.sheet.upsert.error)
      return false
    }
  }

  const remove = async () => {
    await sheetFeature.remove({ uuid: data.uuid })
    pullList()
  }

  return (
    <SlideIn
      title={t.sheet.upsert.title[data.uuid ? 'update' : 'create']}
      formCallback={save}
      removeCallback={initData && remove}
      removeItemName={initData && data.name}
      Trigger={
        initData ? (
          <div>
            <Tooltip text={t.generic.settings}>
              <Cog6ToothIcon className="w-6 stroke-black" />
            </Tooltip>
          </div>
        ) : (
          <div className="">
            <PlusIcon className="w-5 cursor-pointer" />
          </div>
        )
      }
    >
      <div className="flex flex-col gap-3">
        <Input invert label={t.sheet.attributes.name} value={data.name} onChange={set('name')} required />
        <Button className="ml-auto" type={Type.SUBMIT} invert text={t.generic.save} />
      </div>
    </SlideIn>
  )
}

export default Upsert
