import { useCallback, useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Customer } from '../../types/Customer'
import Routes from '../../redux/routes'

const useGetCustomer = (uuid: string): Customer => {
  const loadCustomer = functional.use(Routes.LOAD_CUSTOMER)
  const [customer, setCustomer] = useState<Customer>(null)

  const pull = useCallback(async () => {
    const customer = await loadCustomer({ uuid })
    if (customer) {
      setCustomer(customer)
    }
  }, [loadCustomer, uuid])

  useEffect(() => {
    if (!customer && !!uuid) pull()
  }, [customer, pull, uuid])

  return customer
}

export default useGetCustomer
