import props from '../../../../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import useGetCompleteSheet, { Pull } from '../../../../../hooks/useGetCompleteSheet'
import { useEffect } from 'react'
import { setLocal } from '../../../../../../redux/action/local'
import { Page } from '../../../../../../types/Page'
import { Sheet } from '../../../../../../types/Sheet'
import useAddPage from './useAddPage'
import useRemovePage from './useRemovePage'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import useToast, { Type } from '../../../../../hooks/useToast'

type GetSheet = () => Sheet
type UpdatePage = (page: Page) => Promise<void>
type GetPage = (uuid: string) => Page
type AddPage = () => Promise<boolean>
type Save = () => Promise<void>

export type SheetManager = {
  getSheet?: GetSheet
  pullSheet?: Pull
  updatePage?: UpdatePage
  getPage: GetPage
  addPage?: AddPage
  removePage?: (pageUUID: string) => Promise<boolean>
  save?: Save
}

const useSheetManager = (sheetUUID: string): SheetManager => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const saveSheetAndPages = functional.use(Routes.SAVE_SHEET_AND_PAGES)
  const localSheet: Sheet = useSelector((s) => s[props.LOCAL_SHEET])
  const [sheet, pull] = useGetCompleteSheet(sheetUUID)
  const dispatch = useDispatch()
  const addPage = useAddPage(sheet?.uuid)
  const removePage = useRemovePage()

  useEffect(() => {
    if (!localSheet || localSheet.uuid !== sheetUUID) {
      dispatch(setLocal(props.LOCAL_SHEET, sheet))
    }
  }, [localSheet, sheet, sheetUUID, dispatch])

  const updatePage: UpdatePage = async (page) => {
    const updatedSheet = {
      ...localSheet,
      pages: localSheet.pages.map((localPage) => {
        return localPage.uuid === page.uuid ? page : localPage
      }),
    }
    dispatch(setLocal(props.LOCAL_SHEET, updatedSheet))
  }

  const getPage: GetPage = (uuid) => {
    return localSheet.pages.find((page) => page.uuid === uuid)
  }

  const getSheet = () => {
    return localSheet
  }

  const addPageLocal = async () => {
    const newPage = await addPage()
    if (newPage) {
      dispatch(setLocal(props.LOCAL_SHEET, { ...localSheet, pages: [newPage, ...localSheet.pages] }))
    }
    return !!newPage
  }

  const removePageLocal = async (pageUUID: string) => {
    const status = await removePage(pageUUID)
    if (status) {
      dispatch(setLocal(props.LOCAL_SHEET, { ...localSheet, pages: localSheet.pages.filter((p) => p.uuid !== pageUUID) }))
    }
    return status
  }

  const save = async () => {
    const status = await saveSheetAndPages({ sheet: localSheet })
    console.log(status)

    if (!!status) {
      toast(t.sheet.upsert.success, Type.SUCCESS)
    } else {
      toast(t.sheet.upsert.error)
    }
  }

  return {
    getSheet,
    pullSheet: pull,
    updatePage,
    getPage,
    addPage: addPageLocal,
    removePage: removePageLocal,
    save,
  }
}

export default useSheetManager
