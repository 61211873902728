import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import Input from '../Input/Input'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useRef, useState } from 'react'
import { Transition } from '@headlessui/react'

const Search: React.FC<{ setSearch: (search: string) => void; search: string }> = ({ setSearch, search }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>()

  const toggle = () => {
    if (!open) {
      setOpen(true)
      setTimeout(() => {
        if (!!inputRef?.current) {
          inputRef.current.focus()
        }
      }, 100)
    } else {
      setOpen(false)
    }
  }

  return (
    <div className="relative">
      <MagnifyingGlassIcon className="w-5 text-gray cursor-pointer" onClick={toggle} />
      <Transition
        show={open}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute w-52 right-0 top-2">
          <Input ref={inputRef} onBlur={toggle} placeholder={t.UI.simpleList.search} onChange={setSearch} value={search} className="mt-5" />
        </div>
      </Transition>
    </div>
  )
}

export default Search
