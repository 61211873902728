import { StyledView, Image, Video, PDF } from './View.Styled'
import { s3PublicStoragePrefix } from '../../../../../../utility'
import { Page } from '../../../../../../types/Page'

type Props = {
  page: Page
}

const View: React.FC<Props> = ({ page }) => {
  const getFile = () => {
    if (page.fileKey) {
      return { url: s3PublicStoragePrefix + page.fileKey, type: page.fileType }
    }
  }

  const isFileType = (type) => {
    const file = getFile()
    if (!file) return false
    return file.type.startsWith(type)
  }

  return (
    <StyledView>
      {isFileType('image/') && <Image src={getFile().url} />}
      {isFileType('video/') && (
        <Video width="100%" controls>
          <source src={getFile().url} type={getFile().type} />
        </Video>
      )}
      {isFileType('application/pdf') && <PDF src={getFile().url} allowfullscreen></PDF>}
    </StyledView>
  )
}

export default View
