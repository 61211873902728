import { useState } from 'react'
import Input from '../../UI/Input/Input'
import { useSelector } from 'react-redux'
import Loader from '../../UI/Loading/Loading'
import props from '../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import useToast, { Type } from '../../hooks/useToast'
import Page from '../../Public/Sheet/SheetView/Page/Page'
import { Page as PageType } from '../../../types/Page'
import Container from '../../UI/Container/Container'
import getSinglePageSheetManager from '../../Public/Sheet/SheetView/Page/useSheetManager/getSinglePageSheetManager'

const GlobalSearch = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const searchGlobal = functional.use(Routes.GLOBAL_SEARCH)
  const [pages, setPages] = useState(null)
  const [input, setInput] = useState('')
  const toast = useToast()
  const [searchIsEnabled, setSearchIsEnabled] = useState(false)

  const listPages = async (input) => {
    const pages = await searchGlobal({ input })
    if (pages) {
      setPages(pages)
    } else {
      toast(Type.ERROR, t.sheet.error.loading)
    }
  }

  const search = (input) => {
    setInput(input)
    if (input.trim().length > 0) {
      setSearchIsEnabled(true)
      listPages(input)
    } else {
      setSearchIsEnabled(false)
    }
  }

  return (
    <Container>
      <div className="my-6">
        <Input value={input} onChange={search} placeholder={t.globalSearch.placeholder} />
      </div>
      {searchIsEnabled && <Loader loading={pages} />}
      {searchIsEnabled &&
        Array.isArray(pages) &&
        pages.map((p: PageType) => <Page key={p.uuid} pageUUID={p.uuid} sheetManager={getSinglePageSheetManager(p)} readOnly />)}
    </Container>
  )
}

export default GlobalSearch
