import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import Modal from '../Modal/Modal'
import Input from '../Input/Input'

type Props = {
  itemName: string
  callback: (name: string) => void
  children: React.ReactNode
}

const CopyConfirmation: React.FC<Props> = ({ itemName, callback, children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])
  const [name, setName] = useState<string>(`${itemName}${t.UI.copyConfirmation.initNameSuffix}`)

  const toggle = () => setOpen(!open)

  const submit = () => {
    callback(name)
    setOpen(false)
  }

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal noForm onConfirm={submit} show={open} cancelButtonText={t.generic.cancel} onClose={toggle} title={t.UI.copyConfirmation.title}>
        <div className="flex flex-col gap-3 mt-3">{t.UI.copyConfirmation.text(itemName)}</div>
        <Input value={name} onChange={setName} />
      </Modal>
    </>
  )
}

export default CopyConfirmation
