import Form from '../UI/Form/Form'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import { Level } from '../../types/Auth'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import useToast from '../hooks/useToast'
import bg from '../../assets/image/login/bg.png'
import signet from '../../assets/image/logo/anchor.png'
import { Background } from './Login.Styled'

const Login = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()
  const toast = useToast()

  const submit = async () => {
    const token = await auth.login(mail, password)

    if (token) {
      cookies.set('token', token, { path: '/' })

      const accountLink = await auth.resolveToken(token)

      if (accountLink) {
        if (accountLink.level === Level.ADMIN) {
          navigate('/admin')
        } else if (accountLink.level === Level.MEMBER) {
          navigate('/member')
        }
      }
    } else {
      toast(t.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false} hideNavigation hideHeader>
      <Background src={bg}>
        <Form onSubmit={submit} className="max-w-screen-md mx-auto flex flex-col md:flex-row h-full items-center justify-center gap-5">
          <img src={signet} alt="signet" className="max-w-full h-20" />
          <Input placeholder={t.generic.mail} onChange={setMail} value={mail} required={true} />
          <Input placeholder={t.generic.password} onChange={setPassword} value={password} required={true} type="password" />
          <Button transparent type="submit" text={t.login.cta} />
        </Form>
      </Background>
    </Layout>
  )
}

export default Login
