import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useToast, { Type } from '../../hooks/useToast'
import Tooltip from '../Tooltip/Tooltip'

type Props = {
  children: React.ReactNode
  value: string
  tooltip?: string
}

const Copy: React.FC<Props> = ({ children, value, tooltip }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()

  const copyValue = () => {
    navigator.clipboard.writeText(value)
    toast(t.UI.copy.success, Type.SUCCESS)
  }

  if (!!tooltip) {
    return (
      <Tooltip text={tooltip}>
        <div onClick={copyValue}>{children}</div>
      </Tooltip>
    )
  }
  return <div onClick={copyValue}>{children}</div>
}

export default Copy
