import { useCallback, useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import { Sheet } from '../../types/Sheet'

type Pull = () => Promise<void>

const useLoadSheets = (customerUUID: string): [Sheet[] | null, Pull] => {
  const sheetFeature = crud.use(props.SHEET)
  const [sheets, setSheets] = useState<Sheet[]>(null)

  const pull = useCallback(async () => {
    const sheets = await sheetFeature.list({ customerUUID })
    if (sheets) {
      setSheets(sheets)
    }
  }, [sheetFeature, customerUUID])

  useEffect(() => {
    if (!sheets) pull()
  }, [sheets, pull])

  return [sheets, pull]
}

export default useLoadSheets
