import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import LargeContainer from '../../UI/Container/LargeContainer'
import { Customer } from '../../../types/Customer'
import SimpleList from '../../UI/SimpleList/SimpleList'
import Item from './Item'
import Upsert from './Upsert'
import HeadlineSplit from '../../UI/Headline/Split'
import { useParams } from 'react-router-dom'
import useLoadSheets from '../../hooks/useLoadSheets'
import useGetCustomer from '../../hooks/useGetCustomer'
import Image from '../../UI/Image/Image'

const Sheet = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const [sheets, pull] = useLoadSheets(uuid)
  const customer = useGetCustomer(uuid)

  const filter = (search: string) => (item: Customer) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const Header = () => (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <div className="w-10/12 font-bold text-black">{t.customer.attributes.name}</div>
      <div className="w-2/12"></div>
    </div>
  )

  if (!customer) return null
  return (
    <LargeContainer>
      <div className="flex flex-col gap-1 pt-2 pb-10 mt-10">
        <div className="flex items-center mt-5 gap-5">
          {!!customer.imageURL && (
            <div className="flex justify-center">
              <Image src={customer?.imageURL} className="w-[75px] h-[75px]" />
            </div>
          )}
          <HeadlineSplit
            prefix={t.sheet.list.titleSplit.prefix}
            suffix={t.sheet.list.titleSplit.suffix}
            subline={t.sheet.list.titleSplit.subline(customer.name)}
          />
        </div>
        <SimpleList data={sheets} filter={filter} Component={Item} pull={pull} Upsert={Upsert} Header={Header} />
      </div>
    </LargeContainer>
  )
}

export default Sheet
