import { useCallback, useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { Customer } from '../../types/Customer'
import props from '../../redux/props'

type Pull = () => Promise<void>
type Query = {
  uuid?: string
}

const useLoadCustomers = (query: Query = {}): [Customer[] | null, Pull] => {
  const customerFeature = crud.use(props.CUSTOMER)
  const [customers, setCustomers] = useState<Customer[]>(null)

  const pull = useCallback(async () => {
    const customers = await customerFeature.list(query)
    if (customers) {
      setCustomers(customers)
    }
  }, [customerFeature, query])

  useEffect(() => {
    if (!customers) pull()
  }, [customers, pull])

  return [customers, pull]
}

export default useLoadCustomers
