import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { s3PublicStoragePrefix } from '../../../../../../utility'
import { Page } from '../../../../../../types/Page'
import Button from '../../../../../UI/Button/Button'

type Props = {
  page: Page
}

const Download: React.FC<Props> = ({ page }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const trigger = () => {
    const suffixSplit = page.fileKey.split('.')
    const fileName = 'download.' + suffixSplit[1]

    const link = document.createElement('a')
    link.download = fileName
    link.target = '_blank'
    link.href = s3PublicStoragePrefix + page.fileKey
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (!page.fileKey) return <></>
  return <Button onClick={trigger} text={t.sheet.page.download} invert className="w-full mb-3" />
}

export default Download
