import { useSelector } from 'react-redux'
import View from './View/View'
import Download from './Download/Download'
import props from '../../../../../redux/props'
import RemoveConfirmation from '../../../../UI/RemoveConfirmation/RemoveConfirmation'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import { TrashIcon } from '@heroicons/react/24/solid'
import { pageMaxFileSizeInBytes, pageMimeTypes } from '../../../../../utility'
import Textarea from '../../../../UI/Textarea/Textarea'
import Input from '../../../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import { SheetManager } from './useSheetManager/useSheetManager'
import useToast, { Type } from '../../../../hooks/useToast'

type Props = {
  sheetManager: SheetManager
  pageUUID: string
  readOnly?: boolean
  noBorder?: boolean
}

const Page: React.FC<Props> = ({ pageUUID, sheetManager, readOnly = false, noBorder = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const page = sheetManager.getPage(pageUUID)

  const removePage = async () => {
    const status = await sheetManager.removePage(page.uuid)
    if (!status) {
      toast(Type.ERROR, t.sheet.page.error.remove)
    }
  }

  const updateProp = (prop) => (value) => {
    sheetManager.updatePage({ ...page, [prop]: value })
  }

  const getDay = () => {
    const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    return days[new Date(page.date).getDay()]
  }

  const onFile = (file: OnCallbackFile) => sheetManager.updatePage({ ...page, fileKey: file.key, fileType: file.mimeType })

  return (
    <div id={`page-${pageUUID}`} className={!noBorder && 'mt-5 rounded-md shadow-md p-3 bg-white'}>
      {!readOnly && (
        <div className="mb-3 flex justify-end">
          <RemoveConfirmation itemName={page.topic || ''} confirmationCallback={removePage}>
            <TrashIcon className="fill-red w-5 cursor-pointer" />
          </RemoveConfirmation>
        </div>
      )}
      <div className="block md:flex flex-nowrap gap-3">
        <div className="w-full mb-3 md:w-1/2 overflow-hidden relative">
          <View page={page} />
          <Download page={page} />
          {!readOnly && (
            <DirectFileUpload
              uploadType={UPLOAD_TYPES.PAGE_ASSET}
              allowedMimeTypes={pageMimeTypes}
              maxFileSizeInBytes={pageMaxFileSizeInBytes}
              onFilesCallback={onFile}
              label={t.sheet.page.imageSelection}
              hint={t.sheet.page.imageSelectionHint}
            />
          )}
          <Textarea
            className="mt-[15px] rounded-md"
            value={page.fileComment}
            onChange={updateProp('fileComment')}
            placeholder={t.sheet.page.fileComment}
            label={t.sheet.page.fileComment}
            disabled={readOnly}
            rows={5}
          />
        </div>
        <div className="w-full mb-3 md:w-1/2 overflow-hidden relative">
          <Input
            value={page.topic}
            onChange={updateProp('topic')}
            label={t.sheet.page.topic}
            placeholder={t.sheet.page.topic}
            disabled={readOnly}
            contentClassName="rounded-md"
          />
          <Input
            type="date"
            value={page.date}
            onChange={updateProp('date')}
            label={t.sheet.page.date}
            disabled={readOnly}
            contentClassName="rounded-md"
          />
          <div className="text-sm my-3">
            {t.sheet.page.weekday}
            {getDay()}
          </div>
          <Input
            type="time"
            value={page.time}
            onChange={updateProp('time')}
            label={t.sheet.page.time}
            disabled={readOnly}
            contentClassName="rounded-md"
          />
          <Textarea
            rows={14}
            value={page.text}
            onChange={updateProp('text')}
            label={t.sheet.page.text}
            placeholder={t.sheet.page.text}
            disabled={readOnly}
            className="rounded-md"
          />
          <Button onClick={() => navigator.clipboard.writeText(page.text)} text={t.sheet.page.copy} invert className="w-full" />
        </div>
      </div>
      {!!readOnly && <Button href={`/public/sheet/${page.sheetUUID}`} newTab text={t.sheet.page.goToSheet} invert className="w-full" />}
    </div>
  )
}

export default Page
