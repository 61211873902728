import { Route, Routes } from 'react-router-dom'
import List from './List/List'

const Customer = () => {
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Customer
