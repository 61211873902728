import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import LargeContainer from '../../../UI/Container/LargeContainer'
import useLoadCustomers from '../../../hooks/useLoadCustomers'
import { Customer } from '../../../../types/Customer'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import Item from './Item'
import Upsert from '../Upsert/Upsert'
import HeadlineSplit from '../../../UI/Headline/Split'
// import ImportLegacyData from './ImportLegacyData'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [customers, pull] = useLoadCustomers()

  const filter = (search: string) => (item: Customer) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const Header = () => (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <div className="h-[30px] aspect-square" />
      <div className="w-10/12 font-bold text-black">{t.customer.attributes.name}</div>
      <div className="w-2/12"></div>
    </div>
  )

  return (
    <LargeContainer>
      {/* <ImportLegacyData /> */}
      <div className="flex flex-col gap-1 pt-2 pb-10 mt-10">
        <HeadlineSplit
          prefix={t.customer.list.titleSplit.prefix}
          suffix={t.customer.list.titleSplit.suffix}
          subline={t.customer.list.titleSplit.subline}
        />
        <SimpleList data={customers} filter={filter} Component={Item} pull={pull} Upsert={Upsert} Header={Header} />
      </div>
    </LargeContainer>
  )
}

export default List
