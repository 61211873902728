enum Routes {
  // admin routes
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  IMPORT_LEGACY_DATA = 'IMPORT_LEGACY_DATA',

  // public routes
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',
  LOAD_CUSTOMER = 'LOAD_CUSTOMER',
  GET_COMPLETE_SHEET = 'GET_COMPLETE_SHEET',
  SAVE_SHEET_AND_PAGES = 'SAVE_SHEET_AND_PAGES',
}

export default Routes
