import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { getUUID } from '../../../../../utility'
import { Page } from '../../../../../types/Page'

const PageOverview = ({ pages }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="hidden xl:flex fixed z-10 top-0 left-3 h-screen items-start pt-40">
      <div className="gap-2 flex w-[200px] flex-col">
        {pages.map((p: Page, i: number) => (
          <a
            key={getUUID()}
            href={`#page-${p.uuid}`}
            className="bg-white text-ellipsis overflow-hidden text-nowrap py-1 px-2 block rounded-md w-full shadow-md"
          >
            {i + 1}. {p.topic || translation.sheet.pageOverview.noTopic}
          </a>
        ))}
      </div>
    </div>
  )
}

export default PageOverview
