import React, { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { auth } from '@think-internet/zeus-frontend-package'
import cookie from '../../helper/cookie'
import props from '../../../../redux/props'
import { setLocal } from '../../../../redux/action/local'
import { AuthLevelRequired } from '../../../../types/Auth'

type Props = {
  children: ReactNode
  authLevelRequired?: AuthLevelRequired
}

const Secure: React.FC<Props> = ({ children, authLevelRequired = [] }) => {
  const token = useSelector((state) => state[props.TOKEN])
  const accountLink = useSelector((state) => state[props.ACCOUNT_LINK])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const eject = () => {
      cookie.remove('token')
      dispatch(setLocal(props.ACCOUNT_LINK, null))
      dispatch(setLocal(props.TOKEN, null))
      navigate('/login')
    }

    const evaluate = async () => {
      const token = cookie.get('token')
      let accountLink = null
      if (token) {
        accountLink = await auth.resolveToken(token)
      }

      if (authLevelRequired) {
        if (!!accountLink?.level) {
          if (authLevelRequired.includes(accountLink.level)) {
            dispatch(setLocal(props.ACCOUNT_LINK, accountLink))
            dispatch(setLocal(props.TOKEN, token))
          } else {
            eject()
          }
        } else {
          eject()
        }
      } else if (!!accountLink) {
        dispatch(setLocal(props.ACCOUNT_LINK, accountLink))
        dispatch(setLocal(props.TOKEN, token))
      } else {
        cookie.remove('token')
      }
    }
    if (!token && !accountLink) {
      evaluate()
    }
  }, [authLevelRequired, dispatch, navigate, token, accountLink, pathname, searchParams])

  return (!!token && !!accountLink) || (!authLevelRequired && !cookie.get('token')) ? <>{children}</> : <></>
}

export default Secure
