import { useState } from 'react'
import Input from '../../../UI/Input/Input'
import { Customer } from '../../../../types/Customer'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../UI/DirectFileUpload/DirectFileUpload'
import { companyLogoMaxFileSizeInBytes, companyLogoMimeTypes, s3PublicStoragePrefix } from '../../../../utility'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../../hooks/useToast'
import Button, { Type } from '../../../UI/Button/Button'
import SlideIn from '../../../UI/SlideIn/SlideIn'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'

type Props = {
  initData?: Customer
  pullList?: () => void
}

const Upsert: React.FC<Props> = ({ initData, pullList }) => {
  const [data, setData] = useState<Customer>(initData || ({} as Customer))
  const t = useSelector((s) => s[props.TRANSLATION])
  const customerFeature = crud.use(props.CUSTOMER)
  const toast = useToast()
  const navigate = useNavigate()

  const set = (key: keyof Customer) => (value: any) => setData({ ...data, [key]: value })

  const onImage = (file: OnCallbackFile) => {
    if (file && file.key) {
      setData({ ...data, imageKey: file.key, imageURL: s3PublicStoragePrefix + file.key })
    }
  }

  const save = async (): Promise<boolean> => {
    const customer = await customerFeature.upsert(data)
    if (customer) {
      if (!!initData) {
        pullList()
        toast(t.customer.upsert.success, ToastType.SUCCESS)
      } else {
        navigate(`/admin/project/${customer.uuid}`)
      }
      return true
    } else {
      toast(t.customer.upsert.error)
      return false
    }
  }

  const remove = async () => {
    await customerFeature.remove({ uuid: data.uuid })
    pullList()
  }

  return (
    <SlideIn
      title={t.customer.upsert.title[data.uuid ? 'update' : 'create']}
      formCallback={save}
      removeCallback={initData && remove}
      removeItemName={initData && data.name}
      Trigger={
        initData ? (
          <div>
            <Tooltip text={t.generic.settings}>
              <Cog6ToothIcon className="w-6 stroke-black" />
            </Tooltip>
          </div>
        ) : (
          <div className="">
            <PlusIcon className="w-5 cursor-pointer" />
          </div>
        )
      }
    >
      <div className="flex flex-col gap-3">
        <Input invert label={t.customer.attributes.name} value={data.name} onChange={set('name')} required />
        <DirectFileUpload
          uploadType={UPLOAD_TYPES.CUSTOMER_IMAGE}
          allowedMimeTypes={companyLogoMimeTypes}
          maxFileSizeInBytes={companyLogoMaxFileSizeInBytes}
          onFilesCallback={onImage}
          label={t.customer.upsert.logoUploadLabel}
        />
        <img src={data.imageURL} alt={data.name} className="max-h-40 w-full object-contain object-left" />
        <Button className="ml-auto" type={Type.SUBMIT} invert text={t.generic.save} />
      </div>
    </SlideIn>
  )
}

export default Upsert
