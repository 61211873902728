import { Page } from '../../../../../../types/Page'
import { SheetManager } from './useSheetManager'

const getSinglePageSheetManager = (page: Page): SheetManager => {
  const getPage = () => {
    return page
  }

  return {
    getPage,
  }
}

export default getSinglePageSheetManager
