import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useToast from '../../../../hooks/useToast'
import { Sheet } from '../../../../../types/Sheet'
import useSheetManager from '../Page/useSheetManager/useSheetManager'

type Props = {
  sheet: Sheet
}

const NewPage: React.FC<Props> = ({ sheet }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const sheetManager = useSheetManager(sheet.uuid)
  const addPage = sheetManager.addPage
  const toast = useToast()

  const add = async () => {
    const status = await addPage()
    if (!status) {
      toast(t.sheet.page.error.add)
    }
  }

  return (
    <div className="text-center md:text-xl p-6 mt-5 bg-white cursor-pointer rounded-md shadow-md" onClick={add}>
      {t.sheet.page.new}
    </div>
  )
}
export default NewPage
