import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../../../redux/props'
import { Page } from '../../../../../../types/Page'

export type Add = () => Promise<Page>

const useAddPage = (sheetUUID: string): Add => {
  const pageFeature = crud.use(props.PAGE)

  const add = async () => {
    const page = await pageFeature.create({ sheetUUID })
    return page
  }

  return add
}

export default useAddPage
