const { default: styled } = require('styled-components')

export const StyledView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500px;
  position: relative;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 500px;
  margin-bottom: 15px;
`
export const Video = styled.video`
  max-width: 100%;
  max-height: 500px;
  margin-bottom: 15px;
`

export const PDF = styled.iframe`
  width: 100%;
  margin-bottom: 15px;
  height: 45vh;
`
