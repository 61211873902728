import { useCallback, useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Sheet } from '../../types/Sheet'
import Routes from '../../redux/routes'

export type Pull = () => Promise<void>

const useGetCompleteSheet = (uuid: string): [Sheet, Pull] => {
  const getCompleteSheet = functional.use(Routes.GET_COMPLETE_SHEET)
  const [sheet, setSheet] = useState<Sheet>(null)

  const pull = useCallback(async () => {
    const sheet = await getCompleteSheet({ uuid })
    if (sheet) {
      setSheet(sheet)
    }
  }, [getCompleteSheet, uuid])

  useEffect(() => {
    if (!sheet) pull()
  }, [sheet, pull])

  return [sheet, pull]
}

export default useGetCompleteSheet
